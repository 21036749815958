<template>
  <div class="payments-modal" data-t="payments-modal">
    <div class="content">
      <Deposit v-if="selectedTabId === 'deposit'" />
      <Withdrawal v-if="selectedTabId === 'withdrawal'" />
      <BuyCrypto v-if="selectedTabId === 'buy'" />
    </div>
    <div v-if="selectedTabId === 'deposit'" class="bottom-section">
      <div class="payment-icons">
        <img src="./assets/visa.svg" />
        <img src="./assets/google-pay.svg" />
        <img src="./assets/apple-pay.svg" />
        <img src="./assets/mastercard.svg" />
      </div>
      <div class="no-crypto">
        <div class="description">
          <div class="title">{{ t('payments.deposit.noCrypto') }}</div>
          <div class="subtitle">{{ t('payments.deposit.buyCrypto') }}</div>
        </div>
        <div class="buy-wrapper">
          <StButton
            class="buy-crypto-button"
            :label="t('payments.deposit.buy')"
            :to="{ query: { modal: 'payments', tab: 'buy' } }"
            type="gray"
            size="m"
            :disabled="!isEnabledBuyCrypto"
          />
          <div v-if="!isEnabledBuyCrypto" class="soon">
            {{ t('buyCrypto.soon') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Deposit from '../Deposit/Deposit.vue'
import Withdrawal from '../Withdrawal/Withdrawal.vue'
import BuyCrypto from '../BuyCrypto/BuyCrypto.vue'

const { t } = useI18n()
const route = useRoute()

const { isEnabledBuyCrypto } = useBuyCryptoEnabled()
const selectedTabId = computed(() => route.query.tab?.toString() ?? 'deposit')
</script>

<style scoped>
.payments-modal {
  display: flex;
  flex-direction: column;
  width: 596px;
}

.navigation {
  padding: var(--spacing-300) var(--spacing-300) 0;
}

.content {
  padding: 0 var(--spacing-300) var(--spacing-300);
}

.bottom-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: var(--spacing-150);
  padding: var(--spacing-150) var(--spacing-300);

  border-top: 1px solid var(--border-tertiary);
}

.payment-icons {
  display: flex;
  gap: var(--spacing-200);
}

.no-crypto {
  display: flex;
  gap: var(--spacing-150);
  align-items: flex-end;

  .title {
    font: var(--desktop-text-sm-semibold);
  }

  .subtitle {
    font: var(--desktop-text-xs-medium);
    color: var(--palette-light-600);
  }

  .buy-crypto-button {
    min-width: var(--spacing-800);
    max-height: var(--spacing-400);
  }
}

.buy-wrapper {
  position: relative;
}

.soon {
  user-select: none;

  position: absolute;
  top: -8px;
  right: -10px;

  padding: var(--spacing-025) var(--spacing-050);

  font: var(--desktop-text-xxs-medium);
  color: var(--text-secondary);

  background: var(--background-ghost-tertiary);
  border-radius: var(--border-radius-full, 999px);
}
</style>
